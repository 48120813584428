import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
            <strong>Privacy Policy</strong> <p>
            CryptoDATA TECH S.R.L. built the Knowledge Vault as
            a Free app. This SERVICE is provided by
            CryptoDATA TECH S.R.L. at no cost and is intended for use as
            is.
        </p> <p>
            This page is used to inform visitors regarding our
            policies with the collection, use, and disclosure of Personal
            Information if anyone decided to use our Service.
        </p> <p>
            If you choose to use our Service, then you agree to
            the collection and use of information in relation to this
            policy. The Personal Information that we collect is
            used for providing and improving the Service. We will not use or share your information with
            anyone except as described in this Privacy Policy.
        </p> <p><strong>Information Collection and Use</strong></p> <p>
            For a better experience, while using our Service, we
            may require you to provide us with certain personally
            identifiable information, including but not limited to Email Address. The information that
            we request will be passed to a third party service provider where it will be used for authentication purposes.
            The third-party service requiring authentication is an optional feature. 
        </p> <div><p>
            The app does use third-party services that may collect
            information used to identify you.
        </p> <p>
            Link to the privacy policy of third-party service providers used
            by the app
        </p> <ul><li><a href="https://prisma-analytics.com/privacy-policy" target="_blank" rel="noopener noreferrer">Prisma Analytics</a></li>
        </ul></div> 
        <p><strong>Log Data</strong></p> <p>
            We want to inform you that whenever you
            use our Service, in a case of an error in the app
            we collect data and information (through third-party
            products) on your phone called Log Data. This Log Data may
            include information such as your device Internet Protocol
            (“IP”) address, device name, operating system version, the
            configuration of the app when utilizing our Service,
            the time and date of your use of the Service, and other
            statistics.
        </p> <p><strong>Service Providers</strong></p> <p>
            We may employ third-party companies and
            individuals due to the following reasons:
        </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
            We want to inform users of this Service
            that these third parties have access to their Personal
            Information. The reason is to perform the tasks assigned to
            them on our behalf. However, they are obligated not to
            disclose or use the information for any other purpose.
        </p> <p><strong>Security</strong></p> <p>
            We value your trust in providing us your
            Personal Information, thus we are striving to use commercially
            acceptable means of protecting it. But remember that no method
            of transmission over the internet, or method of electronic
            storage is 100% secure and reliable, and we cannot
            guarantee its absolute security.
        </p> <p><strong>Links to Other Sites</strong></p> <p>
            This Service may contain links to other sites. If you click on
            a third-party link, you will be directed to that site. Note
            that these external sites are not operated by us.
            Therefore, we strongly advise you to review the
            Privacy Policy of these websites. We have
            no control over and assume no responsibility for the content,
            privacy policies, or practices of any third-party sites or
            services.
        </p> <p><strong>Changes to This Privacy Policy</strong></p> <p>
            We may update our Privacy Policy from
            time to time. Thus, you are advised to review this page
            periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on
            this page.
        </p> <p>This policy is effective as of 2022-09-14</p> <p><strong>Contact Us</strong></p> <p>
            If you have any questions or suggestions about our
            Privacy Policy, do not hesitate to contact us at contact@cryptodata.com.
        </p>
    </div>
  );
}

export default App;
